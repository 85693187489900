/* eslint-disable jsx-a11y/label-has-associated-control */
// import { useTranslation, Trans } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import zxcvbn from 'zxcvbn'
import { selectElementText } from '@/utils/dom'
import { EEFLongWordList } from '@/utils/wordlist'
import Link from '@/components/link-component'
import CheckBox from '@/components/forms/fields/check-box'
import RangeSlider from '@/components/forms/fields/range-slider'
import Spinner from '@/icons/bwi/spinner.svg'
import { cx } from '@/utils/strings'

function SectionPasswordGenerator({ data: { title, description } }) {
  const [pass, setPass] = useState('')
  const [type, setType] = useState('password')
  const [passwordLength, setPasswordLength] = useState(14)
  const [passphraseLength, setPassphraseLength] = useState(5)
  const [uppercase, setUppercase] = useState(true)
  const [lowercase, setLowercase] = useState(true)
  const [numbers, setNumbers] = useState(true)
  const [symbols, setSymbols] = useState(false)
  const [capitalized, setCapitalized] = useState(false)
  const [includeNumber, setIncludeNumber] = useState(false)
  const [wordSeparator, setWordSeparator] = useState('-')
  const [copying, setCopying] = useState(false)
  const [score, setScore] = useState(0)
  const [crackTime, setCrackTime] = useState('')
  const [reload, setReload] = useState(false)

  const lang = useMemo(
    () => ({
      'less than a second': 'less than a second',
      second: 'second',
      seconds: 'seconds',
      minute: 'minute',
      minutes: 'minutes',
      hour: 'hour',
      hours: 'hours',
      day: 'day',
      days: 'days',
      week: 'week',
      weeks: 'weeks',
      month: 'month',
      months: 'months',
      year: 'year',
      years: 'years',
      century: 'century',
      centuries: 'centuries',
    }),
    []
  )

  const scoreObj = {
    0: { score: 'very weak', style: 'text-melonRed' },
    1: { score: 'very weak', style: 'text-melonRed' },
    2: { score: 'weak', style: 'text-amberOrange' },
    3: { score: 'good', style: 'text-indigoBlue' },
    4: { score: 'strong', style: 'text-primaryBlue' },
  }

  const copyText = useCallback((text) => {
    navigator.clipboard.writeText(text)
    setCopying(true)
    selectElementText('newUserPw')
    setTimeout(() => {
      setCopying(false)
    }, 1500)
  }, [])

  // run this when anything on the form changes
  useEffect(() => {
    setCopying(false)
    setPass(
      generatePassword({
        length: passwordLength,
        number: numbers,
        uppercase,
        lowercase,
        special: symbols,
        type,
        numWords: passphraseLength,
        capitalize: capitalized,
        includeNumber,
        wordSeparator,
      })
    )
  }, [
    reload,
    type,
    passwordLength,
    uppercase,
    lowercase,
    numbers,
    symbols,
    passphraseLength,
    capitalize,
    includeNumber,
  ])

  useEffect(() => {
    // calculate password strength
    if (pass.length === 0) return
    const zxcvbnObj = zxcvbn(pass, ['bitwarden', 'bit', 'warden'])
    setScore(zxcvbnObj.score)
    const time = zxcvbnObj.crack_times_display.offline_slow_hashing_1e4_per_second
    const matches = time.match(/^(\d+) (\w+)$/)
    setCrackTime(matches && matches.length === 3 ? `${Number(matches[1])} ${lang[matches[2]]}` : lang[time])
  }, [pass])

  return (
    <div className="lg:max-w-8xl m-auto lg:px-9" id="password-generator">
      <div className="bg-primaryBlue px-5 lg:px-32 py-10 lg:pt-16 lg:pb-20 lg:rounded-4xl">
        <div className="text-center text-white pb-2 lg:pb-6">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="bg-white rounded-4xl">
          <div className="max-w-5xl mx-auto p-5 md:p-12">
            <div className="grid sm:grid-cols-2 gap-2 mb-4">
              <div className="flex items-center flex-row sm:flex-col gap-1">
                <h5 className="mb-0 text-base sm:text-xl">
                  Your password's score:
                  <b className={`mb-0 text-base sm:text-xl ${[scoreObj[score]?.style]}`}>
                    &nbsp;{scoreObj[score]?.score}
                  </b>
                </h5>
              </div>

              <div className="flex items-center flex-row sm:flex-col gap-1">
                <h5 className="mb-0 text-base sm:text-xl">
                  Estimated time to crack:
                  <b className={`mb-0 text-base sm:text-xl ${[scoreObj[score]?.style]}`}>&nbsp;{crackTime}</b>
                </h5>
              </div>
            </div>

            <button
              type="button"
              id="newUserPw"
              key={`${reload}-key`}
              className={cx(
                'relative cursor-text text-center w-full min-h-[128px] font-mono p-3 my-3 bg-gray-200 border-gray-300 border rounded-4xl break-words hover:border-gray-400',
                pass.length < 1 && 'animate-none!',
                pass.length <= 16 && 'text-4xl md:text-6xl',
                pass.length > 16 && pass.length <= 32 && 'text-xl md:text-4xl',
                pass.length > 32 && pass.length <= 64 && 'text-base md:text-3xl',
                pass.length > 64 && pass.length <= 80 && 'text-sm md:text-2xl',
                pass.length > 80 && pass.length <= 130 && 'text-xs md:text-xl',
                pass.length > 130 && 'text-xs md:text-base'
                // css`
                //   @keyframes zoomer {
                //     50% {
                //       transform: scale(1.2);
                //     }
                //     ,
                //     100% {
                //       transform: scale(1);
                //     }
                //   }
                //   animation: zoomer 0.5s cubic-bezier(0, 0, 0.2, 1) 1;
                // `,
              )}
              onMouseDown={() => copyText(pass)}
            >
              <div
                className={cx(
                  'absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] transition-[opacity, transform]',
                  pass.length < 1 ? 'opacity-100' : 'opacity-0 scale-0'
                )}
              >
                <Spinner className="animate-spin h-24 w-24" />
              </div>
              {pass.split('').map((char, i) => (
                <span
                  className={cx(
                    char.match(/[0-9]/) && 'text-primaryBlue',
                    char.match(/[a-z]/) && 'text-indigoBlue',
                    char.match(/[A-Z]/) && 'text-indigoBlue',
                    !char.match(/[0-9a-zA-Z]/) && 'text-melonRed'
                  )}
                  key={i}
                >
                  {char}
                </span>
              ))}
            </button>

            <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 py-2">
              <button
                type="button"
                onClick={() => copyText(pass)}
                className={cx(
                  'font-bold py-2 w-full rounded-4xl hover:bg-indigoBlue',
                  copying ? '!bg-amberOrange !text-indigoBlue' : 'bg-indigoBlue text-white'
                )}
              >
                <span className="font-bwi font-normal mr-1">&#xe905;</span>
                {copying ? 'Copied!' : 'Copy to Clipboard'}
              </button>
              <button
                type="button"
                onClick={() => setReload(!reload)}
                className="font-bold py-2 w-full rounded-4xl bg-primaryBlue text-white hover:bg-indigoBlue"
              >
                <span className="font-bwi font-normal mr-1">&#xe90e;</span>
                Regenerate
              </button>
            </div>

            <form className={cx('grid gap-6 grid-cols-1 sm:grid-cols-2 mt-4')} onChange={() => setReload(!reload)}>
              <div className={cx('flex flex-row gap-4 sm:flex-col sm:gap-0')}>
                <h5 className="mb-0">Type</h5>
                <fieldset className="flex flex-wrap gap-4">
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="password"
                      className="mr-1"
                      onChange={() => setType('password')}
                      defaultChecked
                    />
                    Password
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="passphrase"
                      className="mr-1"
                      onChange={() => setType('passphrase')}
                    />
                    Passphrase
                  </label>
                </fieldset>
              </div>
              <div className="flex items-center flex-row gap-4 sm:flex-col sm:gap-0 sm:items-start">
                {type === 'password' ? (
                  <RangeSlider
                    key="passwordLength"
                    label={`Characters: ${passwordLength}`}
                    defaultValue={passwordLength}
                    min={5}
                    max={128}
                    onUpdate={(val) => setPasswordLength(val)}
                    onUpdateEnd={() => setReload(!reload)}
                  />
                ) : (
                  <RangeSlider
                    key="passphraseLength"
                    label={`Words: ${passphraseLength}`}
                    defaultValue={passphraseLength}
                    min={3}
                    max={20}
                    onUpdate={(val) => setPassphraseLength(val)}
                    onUpdateEnd={() => setReload(!reload)}
                  />
                )}
              </div>
              <div className="sm:col-span-2">
                <h5 className="mb-0">Additional Options</h5>

                {type === 'password' ? (
                  <div className="flex flex-wrap gap-4 sm:gap-8">
                    <CheckBox
                      key="uppercase"
                      label="A-Z"
                      defaultChecked={uppercase}
                      disabled={lowercase === false && numbers === false && symbols === false}
                      onUpdate={(val) => setUppercase(val)}
                    />
                    <CheckBox
                      key="lowercase"
                      label="a-z"
                      defaultChecked={lowercase}
                      disabled={uppercase === false && numbers === false && symbols === false}
                      onUpdate={(val) => setLowercase(val)}
                    />
                    <CheckBox
                      key="numbers"
                      label="0 - 9"
                      defaultChecked={numbers}
                      disabled={uppercase === false && lowercase === false && symbols === false}
                      onUpdate={(val) => setNumbers(val)}
                    />
                    <CheckBox
                      key="symbols"
                      label="!@#$%^&*"
                      defaultChecked={symbols}
                      disabled={numbers === false && lowercase === false && uppercase === false}
                      onUpdate={(val) => setSymbols(val)}
                    />
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-4 sm:gap-8">
                    <CheckBox
                      key="capitalized"
                      label="Capitalize"
                      defaultChecked={capitalized}
                      onUpdate={(val) => setCapitalized(val)}
                    />
                    <CheckBox
                      key="includeNumber"
                      label="Include Number"
                      defaultChecked={includeNumber}
                      onUpdate={(val) => setIncludeNumber(val)}
                    />

                    <label htmlFor="ws" className="cursor-pointer">
                      <input
                        id="ws"
                        type="text"
                        className="w-8 font-mono py-1 pl-[11px] mr-1 text-lg border outline-none border-gray-300 rounded-4xl hover:border-gray-500"
                        maxLength={1}
                        min={0}
                        value={wordSeparator}
                        onFocus={(e) => {
                          e.target.select()
                        }}
                        onChange={(e) => {
                          e.target.select()
                          setWordSeparator(e.target.value)
                        }}
                      />
                      Word separator
                    </label>
                  </div>
                )}
              </div>
            </form>

            <p className="mt-6">
              Want to test the strength of another password? Try the Bitwarden{' '}
              <Link to="/password-strength/" className="blue-link">
                Password Strength Testing Tool
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionPasswordGenerator

const DefaultOptions = {
  length: 14,
  ambiguous: false,
  number: true,
  minNumber: 1,
  uppercase: true,
  minUppercase: 0,
  lowercase: true,
  minLowercase: 0,
  special: false,
  minSpecial: 1,
  type: 'password',
  numWords: 3,
  wordSeparator: '-',
  capitalize: false,
  includeNumber: false,
}

function generatePassword(options) {
  // overload defaults with given options
  const o = { ...DefaultOptions, ...options }

  if (o.type === 'passphrase') {
    return generatePassphrase(options)
  }

  // sanitize
  sanitizePasswordLength(o, true)

  const minLength = o.minUppercase + o.minLowercase + o.minNumber + o.minSpecial
  if (o.length < minLength) {
    o.length = minLength
  }

  const positions = []
  if (o.lowercase && o.minLowercase > 0) {
    for (let i = 0; i < o.minLowercase; i += 1) {
      positions.push('l')
    }
  }
  if (o.uppercase && o.minUppercase > 0) {
    for (let i = 0; i < o.minUppercase; i += 1) {
      positions.push('u')
    }
  }
  if (o.number && o.minNumber > 0) {
    for (let i = 0; i < o.minNumber; i += 1) {
      positions.push('n')
    }
  }
  if (o.special && o.minSpecial > 0) {
    for (let i = 0; i < o.minSpecial; i += 1) {
      positions.push('s')
    }
  }
  while (positions.length < o.length) {
    positions.push('a')
  }

  // shuffle
  shuffleArray(positions)

  // build out the char sets
  let allCharSet = ''

  let lowercaseCharSet = 'abcdefghijkmnopqrstuvwxyz'
  if (o.ambiguous) {
    lowercaseCharSet += 'l'
  }
  if (o.lowercase) {
    allCharSet += lowercaseCharSet
  }

  let uppercaseCharSet = 'ABCDEFGHJKLMNPQRSTUVWXYZ'
  if (o.ambiguous) {
    uppercaseCharSet += 'IO'
  }
  if (o.uppercase) {
    allCharSet += uppercaseCharSet
  }

  let numberCharSet = '23456789'
  if (o.ambiguous) {
    numberCharSet += '01'
  }
  if (o.number) {
    allCharSet += numberCharSet
  }

  const specialCharSet = '!@#$%^&*'
  if (o.special) {
    allCharSet += specialCharSet
  }

  let password = ''
  for (let i = 0; i < o.length; i += 1) {
    let positionChars
    switch (positions[i]) {
      case 'l':
        positionChars = lowercaseCharSet
        break
      case 'u':
        positionChars = uppercaseCharSet
        break
      case 'n':
        positionChars = numberCharSet
        break
      case 's':
        positionChars = specialCharSet
        break
      case 'a':
        positionChars = allCharSet
        break
      default:
        break
    }

    const randomCharIndex = randomNumber(0, positionChars.length - 1)
    password += positionChars.charAt(randomCharIndex)
  }

  return password
}

function generatePassphrase(options) {
  const o = { ...DefaultOptions, ...options }

  if (o.numWords == null || o.numWords <= 2) {
    o.numWords = DefaultOptions.numWords
  }
  if (o.wordSeparator == null || o.wordSeparator.length === 0 || o.wordSeparator.length > 1) {
    o.wordSeparator = ' '
  }
  if (o.capitalize == null) {
    o.capitalize = false
  }
  if (o.includeNumber == null) {
    o.includeNumber = false
  }

  const listLength = EEFLongWordList.length - 1
  const wordList = new Array(o.numWords)
  for (let i = 0; i < o.numWords; i += 1) {
    const wordIndex = randomNumber(0, listLength)
    if (o.capitalize) {
      wordList[i] = capitalize(EEFLongWordList[wordIndex])
    } else {
      wordList[i] = EEFLongWordList[wordIndex]
    }
  }

  if (o.includeNumber) {
    appendRandomNumberToRandomWord(wordList)
  }
  return wordList.join(o.wordSeparator)
}

// Helpers

function randomNumber(min, max) {
  let rval = 0
  const range = max - min + 1
  const bitsNeeded = Math.ceil(Math.log2(range))
  if (bitsNeeded > 53) {
    throw new Error('We cannot generate numbers larger than 53 bits.')
  }

  const bytesNeeded = Math.ceil(bitsNeeded / 8)
  const mask = 2 ** bitsNeeded - 1
  // 7776 -> (2^13 = 8192) -1 == 8191 or 0x00001111 11111111

  // Fill a byte array with N random numbers
  const byteArray = new Uint8Array(randomBytes(bytesNeeded))

  let p = (bytesNeeded - 1) * 8
  for (let i = 0; i < bytesNeeded; i += 1) {
    rval += byteArray[i] * 2 ** p
    p -= 8
  }

  // Use & to apply the mask and reduce the number of recursive lookups

  rval &= mask

  if (rval >= range) {
    // Integer out of acceptable range
    return randomNumber(min, max)
  }

  // Return an integer that falls within the range
  return min + rval
}

function randomBytes(length) {
  const arr = new Uint8Array(length)
  window.crypto.getRandomValues(arr)
  return arr.buffer
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = randomNumber(0, i)
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function appendRandomNumberToRandomWord(wordList) {
  if (wordList == null || wordList.length <= 0) {
    return
  }
  const index = randomNumber(0, wordList.length - 1)
  const num = randomNumber(0, 9)
  wordList[index] += num
}

function sanitizePasswordLength(options, forGeneration) {
  let minUppercaseCalc = 0
  let minLowercaseCalc = 0
  let minNumberCalc = options.minNumber
  let minSpecialCalc = options.minSpecial

  if (options.uppercase && options.minUppercase <= 0) {
    minUppercaseCalc = 1
  } else if (!options.uppercase) {
    minUppercaseCalc = 0
  }

  if (options.lowercase && options.minLowercase <= 0) {
    minLowercaseCalc = 1
  } else if (!options.lowercase) {
    minLowercaseCalc = 0
  }

  if (options.number && options.minNumber <= 0) {
    minNumberCalc = 1
  } else if (!options.number) {
    minNumberCalc = 0
  }

  if (options.special && options.minSpecial <= 0) {
    minSpecialCalc = 1
  } else if (!options.special) {
    minSpecialCalc = 0
  }

  // This should never happen but is a final safety net
  if (!options.length || options.length < 1) {
    options.length = 10
  }

  const minLength = minUppercaseCalc + minLowercaseCalc + minNumberCalc + minSpecialCalc
  // Normalize and Generation both require this modification
  if (options.length < minLength) {
    options.length = minLength
  }

  // Apply other changes if the options object passed in is for generation
  if (forGeneration) {
    options.minUppercase = minUppercaseCalc
    options.minLowercase = minLowercaseCalc
    options.minNumber = minNumberCalc
    options.minSpecial = minSpecialCalc
  }
}
