import { slugify, cx } from '@/utils/strings'

import Quote from '@/components/ui/quote'
import Image from '@/components/ui/image'
import Slider from '@/components/ui/slider/slider'
import type { Entry } from 'contentful'
import type { TypeComponentQuoteSkeleton, TypeResourceImageSkeleton, TypeSectionSliderSkeleton } from '@/types/ctf'
import { useLiveUpdates } from '@/utils/live-preview'

type SectionSlider = Entry<TypeSectionSliderSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type QuoteEntry = Entry<TypeComponentQuoteSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type ImageEntry = Entry<TypeResourceImageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

export type SliderSectionProps = SectionSlider['fields'] & {
  entryId?: string
}

export default function SliderSection({ entryId, ...fields }: SliderSectionProps): JSX.Element {
  const {
    fields: { internalName, headline, slides },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  return (
    <section id={slugify(internalName)} className="!mt-0">
      {headline && (
        <div className="max-w-6xl mx-auto text-indigoBlue md:px-5 mb-12">
          {headline && <h3 className="text-center font-normal">{headline}</h3>}
          <Slider customCss="md:[--bw-slider-nav-button-distance:2.44rem]">
            {slides?.map((slide, index) => {
              if (!slide) return null
              const { fields } = slide
              if (slide.sys.contentType.sys.id === 'componentQuote') {
                const quote = fields as QuoteEntry['fields']
                return (
                  <div key={index} className="mx-4 md:mx-14 mb-14">
                    <Quote {...quote} imageAspectRatio={1} layout="horizontal" />
                  </div>
                )
              }
              if (slide.sys.contentType.sys.id === 'resourceImage') {
                const image = fields as ImageEntry['fields']
                return (
                  <div
                    key={index}
                    className={cx(
                      'mx-14 mb-14 max-w-full h-full flex items-center justify-center',
                      !image.zoomable && 'pointer-events-none'
                    )}
                  >
                    <Image {...image} />
                  </div>
                )
              }
              return null
            })}
          </Slider>
        </div>
      )}
    </section>
  )
}
