import { useMemo } from 'react'
import { Entry } from 'contentful'
import { slugify } from '@/utils/strings'
import ComparisonGrid, { type TableData, type TableRowData } from '@/components/ui/comparison-grid'
import RichText from '@/components/ui/rich-text'
import type {
  TypeComponentComparisonTableSkeleton,
  TypeTableRowSectionHeadingSkeleton,
  TypeTableRowSkeleton,
} from '@/types/ctf'
import { useLiveUpdates } from '@/utils/live-preview'

type ComparisonTableEntry = Entry<TypeComponentComparisonTableSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

type ComparisonTableProps = ComparisonTableEntry['fields'] & {
  entryId?: string
}

export default function ComparisonTable({ entryId, ...fields }: ComparisonTableProps) {
  const {
    fields: { internalName, title, featuresHeading, headerCells, rows, footer, textAlign = 'Center' },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  const data: TableData = useMemo(
    () => ({
      id: internalName ? slugify(internalName) : undefined,
      tableTitle: title,
      subtext: fields.subtext?.data ? <RichText body={fields.subtext as any} /> : undefined,
      headers: [
        featuresHeading ? <RichText key="features" body={featuresHeading} /> : undefined,
        ...(headerCells || []).map((cell) => <RichText key={cell?.sys.id} body={cell?.fields.content as any} />),
      ],
      body: (rows || []).map((row) => {
        switch (row.sys.contentType.sys.id) {
          case 'tableRow':
            const rowData = row.fields as Entry<TypeTableRowSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>['fields']
            return {
              Tooltip: rowData.tooltip ? <RichText body={rowData.tooltip} /> : undefined,
              cells: [
                <RichText key="rowTitle" body={rowData.title} />,
                ...(rowData?.cells?.map((cell) => <RichText key={cell.sys.id} body={cell.fields.content} />) || []),
              ],
            }
          case 'tableRowSectionHeading':
            const sectionHeadingData = row.fields as Entry<
              TypeTableRowSectionHeadingSkeleton,
              'WITHOUT_UNRESOLVABLE_LINKS'
            >['fields']
            return {
              subheader: sectionHeadingData.title,
            }
          default:
            return {}
        }
      }) as TableRowData[],
      footer: footer
        ? [
            {
              Tooltip: footer.fields.tooltip ? <RichText body={footer.fields.tooltip} /> : undefined,
              cells: [
                <RichText key="rowTitle" body={footer.fields.title} />,
                ...(footer.fields.cells?.map((cell) => <RichText key={cell.sys.id} body={cell.fields.content} />) ||
                  []),
              ],
            },
          ]
        : undefined,
    }),
    [title, featuresHeading, headerCells, rows]
  )

  return <ComparisonGrid data={{ content: data }} textAlign={textAlign as 'Left' | 'Center'} />
}
