import React, { useEffect, useRef, useState } from 'react'
import { useDebounceCallback, useEventListener } from 'usehooks-ts'
import { cx } from '@/utils/strings'

export type MarqueeProps = {
  children: React.ReactNode
  isReversed?: boolean
  /**
   * Minimum number of times to repeat the children
   *
   * (Most likely won't need to be adjusted, but can be useful for debugging or edge cases)
   */
  minRepeatCount?: number
  /**
   * Number of times to fill the container with the children
   *
   * (Most likely won't need to be adjusted, but can be useful for debugging or edge cases)
   */
  timesToFill?: number
}

// Speed adjustment of the marquee animation. Higher values are faster.
const SPEED_MULTIPLIER = 0.5

export default function Marquee({ children, isReversed = false, minRepeatCount = 2, timesToFill = 4 }: MarqueeProps) {
  const elementRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [repeatCount, setRepeatCount] = useState(minRepeatCount)
  const [offset, setOffset] = useState(0)
  const [duration, setDuration] = useState(0)

  // Calculate how many times we need to repeat the children to fill the container
  const handleResize = useDebounceCallback(() => {
    if (elementRef.current && wrapperRef.current) {
      const elementWidth = elementRef.current.clientWidth
      const wrapperWidth = wrapperRef.current.clientWidth

      // Set repeat count to make sure items fit in at least timesToFill times of wrapper width
      let count = Math.ceil((wrapperWidth * timesToFill) / elementWidth)
      if (count % 2 !== 0) {
        // The repeat count must be even for the animation to be seamless
        count += 1
      }
      setRepeatCount(count !== Infinity ? Math.max(count, minRepeatCount) : minRepeatCount)
      setOffset(wrapperWidth - elementWidth)
      setDuration((wrapperWidth + elementWidth) / 100 / SPEED_MULTIPLIER)
    }
  }, 250)
  useEventListener('resize', handleResize)
  useEffect(() => {
    handleResize()
  }, [])

  return (
    <div className="overflow-hidden mask-fade-edges">
      <div
        ref={wrapperRef}
        className={cx(
          'flex items-center origin-right transition-opacity',
          isReversed ? 'animate-[marquee_linear_infinite_reverse]' : 'animate-[marquee_linear_infinite]',
          duration === 0 ? 'opacity-0' : 'opacity-100'
        )}
        style={
          {
            '--marquee-start-offset': offset < 0 ? `${offset}px` : '0px',
            '--marquee-end-offset': offset > 0 ? `${-offset}px` : '0px',
            animationDuration: `${duration}s`,
          } as React.CSSProperties
        }
      >
        {Array(repeatCount)
          .fill(children)
          .map((child, index) => (
            <div key={index} ref={index === 0 ? elementRef : null} className="flex shrink-0 transform-gpu">
              {child}
            </div>
          ))}
      </div>
    </div>
  )
}
