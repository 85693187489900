import RichText from '@/components/ui/rich-text'
import HubSpotForm from '@/components/ui/hubspot-form/hubspot-form'
import HubiloForm from '@/components/ui/hubilo-form/hubilo-form'
import { cx } from '@/utils/strings'
import colors from '@/constants/colors'
import type { Entry } from 'contentful'
import type { TypeComponentHubSpotFormSkeleton, TypeFormContainerSkeleton, TypeSectionFormSkeleton } from '@/types/ctf'
import { useLiveUpdates } from '@/utils/live-preview'

type SectionFormEntry = Entry<TypeSectionFormSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type HubspotFormEntry = Entry<TypeComponentHubSpotFormSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

export type Props = SectionFormEntry['fields'] & {
  entryId?: string
}

export default function Form({ entryId, ...contentfulFields }: Props): JSX.Element {
  const {
    fields: { backgroundColor, headline, body, form, anchorId, hideInitially },
  } = useLiveUpdates({ fields: contentfulFields, sys: { id: entryId } })

  const formType = form?.sys.contentType.sys.id

  const { white, primaryBlue, lightGray } = colors

  return (
    <section className={cx('max-w-8xl px-5 lg:px-9 mx-auto', hideInitially && 'hidden')} id={anchorId}>
      <div
        className={cx(
          'rounded-4xl',
          backgroundColor === white && 'bg-white',
          backgroundColor === primaryBlue && 'bg-primaryBlue',
          backgroundColor === lightGray && 'bg-gray-100'
        )}
      >
        <div className="mx-auto max-w-[800px] w-full py-12 px-5 ">
          <div className="px-5">
            {headline && <h3 className="text-center">{headline}</h3>}
            {body && (
              <div className="text-center max-w-3xl mx-auto mb-12">
                <RichText body={body} />
              </div>
            )}
          </div>

          {formType === 'componentHubSpotForm' && <HubSpotForm {...(form as HubspotFormEntry).fields} />}
          {formType === 'componentHubiloForm' && <HubiloForm formId={form.fields.formId} id={form.sys.id} />}
        </div>
      </div>
    </section>
  )
}
