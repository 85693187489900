/* eslint-disable react/no-danger */
import React, { useRef, type ReactNode } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import { cx } from '@/utils/strings'
import { BwTableTooltip, BwTableTooltipTrigger, BwTableTooltipContent } from '@/components/ui/tooltip'
import Collapsible from '@/components/ui/collapsible'
import useStickyHelper from '@/utils/useStickyHelper'

type ComparisonGridProps = {
  data: {
    content: TableData | string
  }
  textAlign?: 'Left' | 'Center'
  entryId?: string
}

export type TableRowData = {
  subheader?: string
  Tooltip?: ReactNode
  cells: (
    | ReactNode
    | {
        linkUrl: string
        linkText: string
        text: string
        icon: string
        subtext: string
      }
  )[]
}

export type TableData = {
  id?: string
  tableTitle: string
  subtext?: React.ReactNode
  headers?: ReactNode[]
  body: TableRowData[]
  footer?: TableRowData[]
}

function ExpandIcon({ className = '', isExpanded, openIcon = '&#xe921;', closeIcon = '&#xe925;' }) {
  const iconStyle = 'absolute inset-0 m-auto transition-[transform,opacity] w-full h-full origin-center leading-[100%]'
  return (
    <span className={`${className} relative inline-block font-bwi text-indigoBlue`}>
      <span
        className={cx(
          iconStyle,
          isExpanded ? `opacity-100 rotate-0` : `opacity-0 -rotate-90`,
          closeIcon === '&#xe925;' && `translate-x-[2px]` // minus-circle icon is off-center
        )}
        dangerouslySetInnerHTML={{ __html: closeIcon }}
      />
      <span
        className={cx(iconStyle, !isExpanded ? `opacity-100 rotate-0` : `opacity-0 rotate-90`)}
        dangerouslySetInnerHTML={{ __html: openIcon }}
      />
    </span>
  )
}

export default function ComparisonGrid({ data, textAlign = 'Center' }: ComparisonGridProps) {
  const tableData: TableData | undefined = typeof data.content === 'object' ? data.content : JSON.parse(data.content)

  const containerRef = useRef<HTMLDivElement>(null)
  const { stickyTracker, top, isStuck, isFloating } = useStickyHelper({ containerRef })

  const columns = tableData?.headers?.length ?? 0
  const hasFooter = tableData?.footer?.length > 0

  const lastRowStyles = (column: number) => [
    'bg-white z-[1]',
    column === 0 ? 'col-span-full md:col-auto md:border-r md:rounded-bl-3xl' : 'md:border-r',
    column === 1 && 'rounded-bl-3xl md:rounded-none',
    column === columns - 1 && 'border-r-0 rounded-br-3xl',
  ]

  const headerRef = useRef<HTMLDivElement>(null)
  const headerHeight = headerRef.current?.offsetHeight || 0
  const textAlignStyle =
    !textAlign || textAlign === 'Center' ? `text-center text-balance` : `text-center text-balance md:text-left`

  const [expanded, setExpanded] = React.useState<Record<number, boolean>>({
    // Expand the first 3 rows by default
    0: true,
    1: true,
    2: true,
  })
  const isSomeExpanded = Object.keys(expanded).some((key) => expanded[key])
  const toggleAllRows = () => {
    if (isSomeExpanded) {
      // Collapse all
      setExpanded({})
    } else {
      // Expand all
      setExpanded(tableData?.body.reduce((acc, _, index) => ({ ...acc, [index]: true }), {}) as Record<number, boolean>)
    }
  }

  const getColsStyle = (columns: number) => {
    switch (columns) {
      case 1:
        return 'md:grid-cols-1'
      case 2:
        return 'grid-cols-1 md:grid-cols-2'
      case 3:
        return 'grid-cols-2 md:grid-cols-3'
      case 4:
        return 'grid-cols-3 md:grid-cols-4'
      case 5:
        return 'grid-cols-4 md:grid-cols-5'
      case 6:
        return 'grid-cols-5 md:grid-cols-6'
      case 7:
        return 'grid-cols-6 md:grid-cols-7'
      case 8:
        return 'grid-cols-7 md:grid-cols-8'
    }
  }

  return (
    <section
      id={tableData?.id ? tableData.id : undefined}
      ref={containerRef}
      className="bg-gray-100 relative [&_.rich-icon]:ml-1 [&_.rich-icon]:!text-[25px] [&_.rich-icon]:text-primaryBlue [&_a]:no-underline [&_p:last-child]:mb-0"
    >
      {/* Mask for hiding grid above sticky header */}
      <div
        className="sticky left-0 z-[2] w-full pointer-events-none"
        style={{
          opacity: isStuck ? 1 : 0,
          top: `${top + headerHeight / 2}px`,
        }}
      >
        <div
          className="absolute top-0 left-0 z-10 w-full from-gray-100 to-transparent bg-gradient-to-b from-90%"
          style={{
            height: `${top + headerHeight / 2}px`,
            top: `-${top + headerHeight / 2}px`,
          }}
        />
      </div>
      <div className="mx-auto max-w-8xl px-5 py-5 md:px-9 md:py-20">
        <h3 className={cx('relative z-[3] text-center', tableData?.subtext ? 'mb-4' : 'mb-10')}>
          {tableData?.tableTitle}
        </h3>
        {tableData?.subtext && <div className="relative z-[3] text-center mb-10">{tableData?.subtext}</div>}
        <div className={cx('grid relative', getColsStyle(columns))}>
          {stickyTracker}
          {/* Floating shadow element */}
          <div
            className={cx(
              'absolute bottom-0 left-0 z-0 w-full m-auto shadow-lg rounded-3xl overflow-hidden',
              isStuck ? 'h-[calc(100%-70px)]' : 'h-[calc(100%-15px)'
            )}
          />
          {/* Headers */}
          {tableData?.headers?.map((header, indexColumn) => {
            const col = `column${indexColumn + 1}`
            return (
              <div
                key={indexColumn}
                ref={indexColumn === 0 ? headerRef : undefined}
                className={cx(
                  'flex w-full justify-between [p]:mb-0 pt-5 pb-3 px-4 text-xl font-bold bg-white',
                  'md:sticky md:z-10 md:text-lg md:pt-4 md:pb-4 md:border-r md:shadow-sm',
                  indexColumn === 0
                    ? `sticky z-10 text-left col-span-full rounded-t-3xl md:rounded-tr-none md:col-auto lg:px-14 lg:text-lg`
                    : `hidden md:block ${textAlignStyle}`,
                  indexColumn === columns - 1 && 'md:rounded-tr-3xl md:border-r-0',
                  isStuck && !isFloating && `!rounded-none border-b-[1px]`,
                  isStuck && isFloating && `shadow-lg border border-l-0 border-b-[1px]`,
                  isStuck &&
                    isFloating &&
                    indexColumn === 0 &&
                    `rounded-tl-3xl rounded-tr-3xl md:rounded-tr-none border-l`,
                  isStuck && isFloating && indexColumn === columns - 1 && `rounded-tr-3xl border-l-0`
                )}
                style={{
                  top: `${top}px`,
                }}
              >
                {header?.[col] || header}
                {indexColumn === 0 && (
                  <button
                    type="button"
                    className="md:hidden translate-x-2 text-[1.188rem] w-[1.188rem] h-[1.188rem] p-1 box-content"
                    onClick={toggleAllRows}
                    aria-label={isSomeExpanded ? 'Collapse all' : 'Expand all'}
                  >
                    <ExpandIcon
                      className="w-[1.188rem] h-[1.188rem]"
                      isExpanded={isSomeExpanded}
                      openIcon="&#xe934;"
                      closeIcon="&#xe93c;"
                    />
                  </button>
                )}
              </div>
            )
          })}
          {/* Body */}
          {tableData?.body.map((row, indexRow, rows) =>
            row.subheader ? (
              <div
                key={indexRow}
                className="px-4 py-7 col-span-full border-b font-bold bg-white z-[1] lg:px-14 lg:text-lg"
              >
                <span>{row.subheader}</span>
              </div>
            ) : (
              tableData?.headers?.map((header, indexColumn) => {
                const col = `column${indexColumn + 1}`
                const isLastRow = rows.length - 1 - indexRow === 0
                const cell = row?.cells?.[indexColumn] || row[col]
                const isExpanded = expanded[indexRow]
                const content =
                  indexColumn === 0 ? ( // First header cell
                    <div className="flex items-center content-between gap-2">
                      {/* Feature heading */}
                      <div className="flex-1">
                        {cell?.linkUrl ? (
                          <>
                            <a className="flex items-center" href={cell.linkUrl}>
                              {cell.text}
                              {cell.icon === 'iconLink' && (
                                <span className="font-bwi ml-1 text-[18px] text-primaryBlue">&#xe91b;</span>
                              )}
                            </a>
                            {cell.subtext && <span className="text-xs">{cell.subtext}</span>}
                          </>
                        ) : (
                          <span className="[a::after]:content-['&#xe91b;'] [a::after]:font-bwi [a::after]:ml-1 [a::after]:text-[18px] [a::after]:text-primaryBlue">
                            {cell}
                          </span>
                        )}
                      </div>
                      <ExpandIcon className="md:hidden w-4 h-4 text-base" isExpanded={isExpanded} />
                    </div>
                  ) : // Body cell
                  cell?.linkText ? (
                    <>
                      <a className="text-primaryBlue" href={cell.linkUrl}>
                        {cell.linkText}
                      </a>
                      <br />
                      {cell.text}
                    </>
                  ) : cell === 'iconTick' ? (
                    <span className="font-bwi text-[25px] text-primaryBlue">&#xe912;</span>
                  ) : typeof cell === 'string' ? (
                    <span dangerouslySetInnerHTML={{ __html: cell }} />
                  ) : (
                    cell
                  )
                return (
                  <div
                    key={indexColumn}
                    className={cx(
                      ...(!hasFooter && isLastRow
                        ? lastRowStyles(indexColumn)
                        : [
                            'bg-white z-[1] md:border-b',
                            indexColumn === 0 ? 'col-span-full md:col-auto md:border-r' : 'border-b md:border-r',
                            indexColumn === columns - 1 && 'border-r-0',
                          ])
                    )}
                  >
                    {col === 'column1' ? (
                      <>
                        {/* Desktop */}
                        <div className="relative w-full h-full [&_button]:w-full [&_button]:p-4 [&_button]:z-[1] hidden md:block">
                          {row.Tooltip && (
                            <BwTableTooltip>
                              <BwTableTooltipTrigger>{content}</BwTableTooltipTrigger>
                              <BwTableTooltipContent className={`[&>p:last-child]:mb-0`}>
                                {row.Tooltip}
                              </BwTableTooltipContent>
                            </BwTableTooltip>
                          )}
                          {/* Sometimes the tooltip button is absolute and ignores the height of the content */}
                          {/* This div is a hidden placeholder to fill the height of the cell */}
                          <div
                            className={cx(
                              'hidden leading-tight text-base h-full md:flex p-4 lg:text-lg lg:px-14 lg:py-6',
                              row.Tooltip && 'opacity-0 z-0'
                            )}
                          >
                            {content}
                          </div>
                        </div>
                        {/* Mobile */}
                        <button
                          type="button"
                          className={cx('w-full p-4 text-left md:hidden', !isExpanded && indexColumn > 0 && 'hidden')}
                          onClick={(e) => {
                            const target = e.target as HTMLElement
                            if (target && !isExpanded) {
                              setTimeout(() => {
                                target.scrollIntoView({ behavior: 'smooth', block: 'center' })
                              }, 100)
                            }
                            setExpanded({ ...expanded, [indexRow]: !isExpanded })
                          }}
                          aria-label={isExpanded ? 'Collapse' : 'Expand'}
                        >
                          <div className="font-semibold">{content}</div>
                        </button>
                        <Collapsible className="md:!h-auto md:!opacity-100" isOpen={isExpanded}>
                          <div className={cx('md:hidden text-sm text-gray-600 pt-1.5 px-4 pb-4')}>
                            {row.Tooltip && row.Tooltip}
                          </div>
                        </Collapsible>
                      </>
                    ) : (
                      <Collapsible className="md:!h-auto md:!opacity-100" isOpen={isExpanded}>
                        <div className={cx(`p-4`, textAlignStyle)}>
                          <div className="pb-2 font-semibold md:hidden">{header?.[col] || header}</div>
                          {content}
                        </div>
                      </Collapsible>
                    )}
                  </div>
                )
              })
            )
          )}
          {/* Footer */}
          {tableData?.footer?.map((row, indexRow) =>
            row.subheader ? (
              <div key={indexRow} className="col-span-full px-4 py-7 md:px-12 border-b font-bold z-[1]">
                {row.subheader}
              </div>
            ) : (
              tableData?.headers?.map((header, indexColumn) => {
                const col = `column${indexColumn + 1}`
                const cell = row?.cells?.[indexColumn] || row[col]
                return (
                  <div
                    key={indexColumn}
                    className={cx(
                      ...lastRowStyles(indexColumn),
                      'pt-4 pb-11 px-4 font-bold lg:text-lg',
                      indexColumn === 0 && 'pb-4 md:pb-11 lg:px-14'
                    )}
                  >
                    {col === 'column1' ? (
                      cell
                    ) : (
                      <div className={textAlignStyle}>
                        <div className="pb-2 font-semibold md:hidden">{header?.[col]}</div>
                        {cell?.text ? (
                          <>
                            <span dangerouslySetInnerHTML={{ __html: cell.text }} />
                            <br />
                            {cell.linkText && (
                              <a className="text-primaryBlue" href={cell.linkUrl}>
                                {cell.linkText}
                              </a>
                            )}
                          </>
                        ) : (
                          cell
                        )}
                      </div>
                    )}
                  </div>
                )
              })
            )
          )}
        </div>
      </div>
    </section>
  )
}
