import { useMemo } from 'react'
import Link from '@/components/link-component'
import { JobType } from '@/utils/greenhouse.ts'

const JobsList = ({ jobs = [] }: { jobs: JobType[] }) => {
  const departments = jobs.reduce((unique: string[], job) => {
    if (unique.includes(job.department)) return unique
    return [...unique, job.department]
  }, [])

  return (
    <section className="max-w-8xl mx-auto px-5 lg:px-9 text-indigoBlue" id="open-positions">
      <div className="text-center mb-10">
        <h3>Open Positions</h3>
        <p>
          We are growing quickly and looking for creative, collaborative, diverse, and determined team members who find
          joy in helping others.
        </p>
      </div>
      <div className="my-8">
        {departments.map((department) => (
          <div key={department} className="mb-12">
            <h3 className="text-2xl">{department}</h3>
            {jobs
              .filter((job) => job.department === department)
              .map(({ greenhouse_id, location, title }) => (
                <Link key={greenhouse_id} to={`/careers/${greenhouse_id}/`}>
                  <div className="rounded-2xl shadow-even-around p-6 mb-6 sm:px-12 sm:py-10 sm:mb-10 md:hover:scale-x-[1.01] md:hover:scale-y-[1.01] transition-all duration-[250ms]">
                    <span className="block text-primaryBlue text-lg leading-tight">{title}</span>
                    <p className="mb-0">{location}</p>
                  </div>
                </Link>
              ))}
          </div>
        ))}
      </div>
    </section>
  )
}
export default JobsList
