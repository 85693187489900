import { useState } from 'react'

import Form from '@/components/forms/form'
import SelectField from '@/components/forms/fields/select-field'
import TextField from '@/components/forms/fields/text-field'
import CopyButton from '@/components/copy-button'
import { emailRegex } from '@/utils/strings'

export default function Host({ data }): JSX.Element {
  const [success, setSuccess] = useState(false)
  const [region, setRegion] = useState(data.region.initialValue)
  const [responseData, setResponseData] = useState([])

  return (
    <section className="max-w-3xl mx-auto px-5">
      {!success ? (
        <Form
          id="host"
          name="request_host"
          functionPath={data.region.options.find((option) => option.id === region)?.endpoint}
          headers={{
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }}
          afterSubmit={(response) => {
            response
              .json()
              .then((data) => {
                setResponseData(data)
                setSuccess(true)
              })
              .catch(() => {
                setResponseData(response)
                setSuccess(false)
              })
          }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <TextField name="email" type="email" label={data.email.label} required pattern={emailRegex} autoFocus />
            <SelectField
              name="region"
              initialValue={region}
              label={data.region.label}
              required
              onUpdate={(val) => setRegion(val)}
            >
              {data.region.options.map((option) => (
                <option value={option.id}>{option.label}</option>
              ))}
            </SelectField>
          </div>
        </Form>
      ) : (
        <div className="my-8 p-4 rounded shadow bg-indigoBlue text-white flex flex-col gap-4">
          {data.response.map((key) => (
            <div className="flex">
              <div className="w-44 uppercase font-bold">{key.label}:</div>
              <code className="mx-3 text-tealBlue">
                {responseData[key.id]}

                <CopyButton text={responseData[key.id]} />
              </code>
            </div>
          ))}
        </div>
      )}
    </section>
  )
}
