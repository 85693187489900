import colors from '@/constants/colors'
import Quote from '@/components/ui/quote'
import { type TypeSectionMasonarySkeleton } from '@/types/ctf/TypeSectionMasonary'
import { cx } from '@/utils/strings'
import type { Entry } from 'contentful'
import { useLiveUpdates } from '@/utils/live-preview'

type MasonrySectionEntry = Entry<TypeSectionMasonarySkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

type SetOfMasonryCardsProps = MasonrySectionEntry['fields'] & {
  entryId?: string
}

/**
 * A Masonary style layout of cards
 *
 * Currently used to layout quotes for the media page https://bitwarden.com/media-spotlight/ <br />
 * Could be expanded in future for to include card components as well as quote components
 */

// eslint-disable-next-line react/function-component-definition
export const SetOfMasonaryCards = ({ entryId, ...fields }: SetOfMasonryCardsProps) => {
  const {
    fields: { cards, backgroundColor },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  const { indigoBlue, primaryBlue, lightGray, white } = colors
  return (
    <section
      className={cx(
        (backgroundColor === white || backgroundColor === null) && `bg-white`,
        backgroundColor === primaryBlue && `bg-primaryBlue`,
        backgroundColor === lightGray && `bg-gray-100`,
        backgroundColor === indigoBlue && `bg-indigoBlue`
      )}
    >
      <div className="max-w-8xl mx-auto px-5 lg:px-9 py-10 lg:py-20">
        <div className="columns-1 sm:columns-2 lg:columns-3 gap-8">
          {Array.isArray(cards) &&
            cards.map((card) => {
              if (!card) return null
              const { attribution, body, image, link, backgroundColor } = card.fields
              const { id } = card.sys
              return (
                <div key={id} className="mb-8 break-inside-avoid-column">
                  <Quote
                    attribution={attribution}
                    backgroundColor={backgroundColor}
                    body={body}
                    image={image}
                    link={link}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}
