// import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'

import zxcvbn from 'zxcvbn'
import TextField from '@/components/forms/fields/text-field'
import { cx } from '@/utils/strings'

function SectionPasswordTest() {
  // const { t } = useTranslation()
  const [pass, setPass] = useState('')
  const renderCount = useRef(0)
  renderCount.current += 1

  const getScoreValues = (score) => {
    if (score < 2) {
      return { score: 'very weak', scoreColor: 'text-melonRed' }
    }
    if (score < 3) {
      return { score: 'weak', scoreColor: 'text-amberOrange' }
    }
    if (score < 4) {
      return { score: 'good', scoreColor: 'text-tealBlue' }
    }
    return { score: 'strong', scoreColor: 'text-primaryBlue' }
  }

  // const stringPass = pass.join('');
  const zxcvbnObj = zxcvbn(pass)

  const { score, scoreColor } = getScoreValues(zxcvbnObj.score)

  const crackTime = zxcvbnObj.crack_times_display.offline_slow_hashing_1e4_per_second
  const lang = {
    'less than a second': 'less than a second',
    second: 'second',
    seconds: 'seconds',
    minute: 'minute',
    minutes: 'minutes',
    hour: 'hour',
    hours: 'hours',
    day: 'day',
    days: 'days',
    week: 'week',
    weeks: 'weeks',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
    century: 'century',
    centuries: 'centuries',
  }
  const matches = crackTime.match(/^(\d+) (\w+)$/)
  const crackTimeLang = matches && matches.length === 3 ? `${Number(matches[1])} ${lang[matches[2]]}` : lang[crackTime]

  return (
    <div className="lg:max-w-8xl m-auto lg:px-9" id="Password-Strength-Testing-Tool">
      <div className="bg-primaryBlue px-5 lg:px-32 py-10 lg:pt-16 lg:pb-20 lg:rounded-4xl">
        <div className="text-center text-white pb-2 lg:pb-6">
          <h1>Password Strength Testing Tool</h1>
          <p>Think you have a strong password? Find out below.</p>
        </div>
        <div className="bg-white rounded-4xl">
          <div className="max-w-5xl mx-auto p-5 md:p-12">
            <TextField
              className={cx('font-mono text-xl my-2 sm:text-4xl sm:my-5 truncate', scoreColor)}
              type="text"
              name="password"
              label="Evaluate your password:"
              value={pass}
              onUpdate={(val) => setPass(val)}
              autoFocus
            />

            <div className="flex flex-wrap justify-between  pt-2 lg:pt-8">
              <div className="w-full md:w-1/2">
                <h5 className="mb-0">Your password strength:</h5>
                <div className={cx('font-bold', scoreColor)}>{renderCount.current && score}</div>
              </div>

              <div className="w-full md:w-1/2">
                <h5 className="mb-0">Estimated time to crack:</h5>
                <div className={cx('font-bold', scoreColor)}>{renderCount.current && crackTimeLang}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionPasswordTest
