import { useCallback } from 'react'
import { Experience } from '@ninetailed/experience.js-react'

import { mapNtExperiences } from '@/utils/ninetailed'
import Image from '@/components/ui/image'
import RichText from '@/components/ui/rich-text'
import { cx, slugify } from '@/utils/strings'
import colors from '@/constants/colors'
import { type TypeSectionSetOfImagesSkeleton } from '@/types/ctf/TypeSectionSetOfImages'
import { useLiveUpdates } from '@/utils/live-preview'
import type { Entry } from 'contentful'
import Spinner from '@/icons/bwi/spinner.svg'
import Marquee from '@/components/ui/marquee'

type SectionOfImagesEntry = Entry<TypeSectionSetOfImagesSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

export type SetOfImageProps = SectionOfImagesEntry['fields'] & {
  className?: string
  pageColor: string
  pageOrderNo: number
  entryId?: string
  displayAsMarquee?: boolean
  reverseMarqueeDirection?: boolean
}

/**
 * A set of images
 * - Requires a minimum of 2 images
 * - Has a maximum of 6 images
 */

const SetOfImagesComponent = ({
  className,
  pageColor,
  pageOrderNo,
  entryId,
  displayAsMarquee,
  reverseMarqueeDirection,
  ...fields
}: SetOfImageProps) => {
  const {
    fields: { heading, body, images, imageSize, internalName },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  const { primaryBlue, indigoBlue } = colors

  const Container = useCallback(
    displayAsMarquee
      ? Marquee
      : ({ children }) => (
          <div
            className={cx(
              'flex justify-center items-center',

              imageSize === '32X32' || imageSize === '64X64'
                ? 'flex flex-wrap justify-center items-center'
                : 'grid gap-3 grid-cols-2',
              images?.length === 4 && 'sm:grid-cols-4',
              images?.length === 5 && 'sm:grid-cols-3 lg:grid-cols-5',
              images?.length === 6 && 'sm:grid-cols-3 lg:grid-cols-6'
            )}
          >
            {children}
          </div>
        ),
    [displayAsMarquee, imageSize, images?.length]
  )

  return images && images.length > 1 ? (
    <section id={slugify(internalName)} className={className}>
      <div className="mx-auto max-w-8xl px-5 lg:px-9">
        {(heading || body) && (
          <div
            className={cx(
              'text-center text-indigoBlue font-normal mb-6 md:mb-12',
              (pageColor === indigoBlue || pageColor === primaryBlue) && `text-white`
            )}
          >
            {pageOrderNo === 1 ? <h2 className="h3">{heading}</h2> : <h3>{heading}</h3>}

            {body && <RichText body={body} />}
          </div>
        )}
        <Container isReversed={reverseMarqueeDirection}>
          {images?.map((image) => (
            <div
              key={image?.sys?.id}
              className={cx(
                'px-4 flex justify-center items-center',
                (imageSize === 'Full' || imageSize === null) && `max-w-[220px] [&_img]:w-full [&_svg]:w-full`,
                imageSize === '32X32' && `[&_img]:w-[32px] [&_img]:m-auto [&_svg]:w-[32px] [&_svg]:m-auto`,
                imageSize === '64X64' && `[&_img]:w-[64px] [&_img]:m-auto [&_svg]:w-[64px] [&_svg]:m-auto`,
                imageSize === '96X96' && `[&_img]:w-[96px] [&_img]:m-auto [&_svg]:w-[96px] [&_svg]:m-auto`,
                imageSize === '128X128' && `[&_img]:w-[128px] [&_img]:m-auto [&_svg]:w-[128px] [&_svg]:m-auto`,
                (imageSize === '32X32' || imageSize === '64X64') && images.length > 4 && `px-3 md:px-6 lg:px-10`,
                (imageSize === '32X32' || imageSize === '64X64') && images.length < 3 && `px-4 md:px-14 lg:px-28`,
                (imageSize === '32X32' || imageSize === '64X64') && images.length <= 4 && `px-4 md:px-10 lg:px-20`
              )}
            >
              <Image image={image?.fields.image} {...image?.fields} />
            </div>
          ))}
        </Container>
      </div>
    </section>
  ) : (
    <div />
  )
}

export default function SetOfImages(props: any): JSX.Element {
  const { nt_experiences, id, pageColor, pageOrderNo } = props

  const Loader = useCallback(
    () => (
      <div className="w-full flex justify-center items-center p-8 h-[400px]">
        <Spinner className="animate-spin h-20 w-20 text-gray-400" />
      </div>
    ),
    []
  )

  return (
    <Experience
      {...props}
      id={id}
      experiences={mapNtExperiences(nt_experiences)}
      component={SetOfImagesComponent}
      loadingComponent={Loader}
      passthroughProps={{ pageColor, pageOrderNo }}
    />
  )
}
