import React from 'react'
import { type DragHandlers, motion, MotionValue } from 'framer-motion'

type SlideProps = {
  active: boolean
  index: number
  relativeIndex: number
  renderSlide: (props: { index: number }) => JSX.Element
  x: MotionValue
  onDragEnd: DragHandlers['onDragEnd']
}

export default function Slide({ active, index, relativeIndex, renderSlide, x, onDragEnd }: SlideProps) {
  const child = React.useMemo(() => renderSlide({ index: relativeIndex }), [relativeIndex, renderSlide])

  return (
    <motion.div
      role="tabpanel"
      aria-label={`Slide ${index + 1}`}
      aria-hidden={!active}
      tabIndex={active ? 0 : -1}
      className="w-full cursor-grab flex-shrink-0"
      whileTap={{ cursor: 'grabbing' }}
      style={{ x }}
      draggable
      drag="x"
      dragElastic={1}
      onDragEnd={onDragEnd}
    >
      {child}
    </motion.div>
  )
}
