interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  min: number
  max: number
  defaultValue: number
  step?: number
  onUpdate?: (value: number) => void
  onUpdateEnd?: () => void
}

export default function RangeSlider({
  label,
  min,
  max,
  defaultValue,
  step = 1,
  onUpdate,
  onUpdateEnd,
  ...rest
}: Props) {
  return (
    <label className="w-full text-xl">
      <span className="mb-2 block h5">{label}</span>
      <input
        type="range"
        className="block w-full cursor-pointer outline-none"
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={(event) => {
          event.stopPropagation()
          onUpdate && onUpdate(Number(event.target.value))
        }}
        onMouseUp={() => onUpdateEnd && onUpdateEnd()}
        onKeyUp={() => onUpdateEnd && onUpdateEnd()}
        step={step}
        {...rest}
      />
    </label>
  )
}
