import React, { type KeyboardEventHandler, useEffect } from 'react'
import {
  type ValueAnimationTransition,
  type PanInfo,
  animate,
  useIsomorphicLayoutEffect,
  useMotionValue,
  useMotionValueEvent,
} from 'framer-motion'
import { useEventListener } from 'usehooks-ts'

import usePrevious from '@/utils/usePrevious'
import useDebouncedCallback from '@/utils/useDebouncedCallback'

import VirtualizedSlide from './virtualized-slide-track'
import { getCircularIndex } from './utils'
import { cx } from '@/utils/strings'

export type SliderProps = {
  children: React.ReactNode
  customCss?: any
  dotColorVariant?: 'primary' | 'white'
}

const DEFAULT_RENDER_COUNT = 2

function NavButton({ children, ...props }: React.ComponentProps<'button'>) {
  return (
    <button type="button" {...props}>
      {children}
    </button>
  )
}

function NavDot({
  active,
  ...props
}: {
  active: boolean
  colorVariant: SliderProps['dotColorVariant']
} & React.ComponentProps<'button'>) {
  return (
    <button
      type="button"
      role="tab"
      aria-current={active ? 'true' : undefined}
      data-testid="slider-nav-dot"
      {...props}
    />
  )
}

const dragTransition: ValueAnimationTransition = {
  type: 'spring',
  bounce: 0,
}
const navigateTransition: ValueAnimationTransition = {
  type: 'spring',
  bounce: 0,
  stiffness: 5000,
  damping: 10,
}

/**
 * Infinite slider component
 */
export default function Slider({ children, customCss, dotColorVariant }: SliderProps) {
  const items = React.Children.toArray(children)
  const x = useMotionValue(0)
  const parentRef = React.useRef<HTMLDivElement>(null)

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [index, setIndex] = React.useState(0)
  const previousIndex = usePrevious(index)
  const activeIndex = getCircularIndex(index, items.length)

  const [renderCount, setRenderCount] = React.useState({
    forward: DEFAULT_RENDER_COUNT,
    behind: DEFAULT_RENDER_COUNT,
  })

  const calculateNewX = (i: number) => -i * (containerRef.current?.clientWidth || 0)

  const handleNavigateLeft = () => {
    animate(x, calculateNewX(index - 1), navigateTransition)
    setIndex(index - 1)
  }
  const handleNavigateRight = () => {
    animate(x, calculateNewX(index + 1), navigateTransition)
    setIndex(index + 1)
  }
  const handleKeyDown: KeyboardEventHandler = React.useCallback(
    (event) => {
      if (event.key === 'ArrowRight') {
        parentRef.current?.focus()
        handleNavigateRight()
      } else if (event.key === 'ArrowLeft') {
        parentRef.current?.focus()
        handleNavigateLeft()
      }
    },
    [index, setIndex]
  )

  const handleEndDrag = (e: Event, dragProps: PanInfo) => {
    if (!dragProps) return
    const clientWidth = containerRef.current?.clientWidth || 0

    const { offset, velocity } = dragProps

    if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
      animate(x, calculateNewX(index), dragTransition)
      return
    }

    if (offset.x > clientWidth / 4) {
      setIndex(index - 1)
    } else if (offset.x < -clientWidth / 4) {
      setIndex(index + 1)
    } else {
      animate(x, calculateNewX(index), dragTransition)
    }
  }

  useEffect(() => {
    const delta = index - (previousIndex || 0)
    if (delta > DEFAULT_RENDER_COUNT) {
      setRenderCount({ forward: delta, behind: DEFAULT_RENDER_COUNT })
    } else if (delta < -DEFAULT_RENDER_COUNT) {
      setRenderCount({ forward: DEFAULT_RENDER_COUNT, behind: Math.abs(delta) })
    }
    const controls = animate(x, calculateNewX(index), dragTransition)

    return () => {
      controls.stop
    }
  }, [index])

  const handleResize = useDebouncedCallback(() => {
    // Render correct x position after resize
    x.set(calculateNewX(index))
  }, 50)

  useIsomorphicLayoutEffect(() => {
    handleResize()
  }, [])
  useEventListener('resize', handleResize)

  useMotionValueEvent(x, 'animationStart', () => {
    if (parentRef.current) {
      parentRef.current.ariaBusy = 'true'
    }
  })

  useMotionValueEvent(x, 'animationComplete', () => {
    if (parentRef.current) {
      parentRef.current.ariaBusy = 'false'
    }

    // Reset render count to default after animation is complete
    if (renderCount.forward !== DEFAULT_RENDER_COUNT || renderCount.behind !== DEFAULT_RENDER_COUNT) {
      setRenderCount({
        forward: DEFAULT_RENDER_COUNT,
        behind: DEFAULT_RENDER_COUNT,
      })
    }
  })

  const NavBtnStyles =
    'absolute top-0 bottom-0 my-auto z-20 flex items-center justify-center w-9 h-9 text-[16px] rounded-full !bg-white shadow-md hover:!bg-gray-100 hover:active:scale-95 will-change-transform transition-[opacity, transform] duration-100 select-none'

  return (
    <div
      role="tablist"
      className={cx(
        'outline-none w-full rounded-4xl overflow-hidden [--bw-slider-nav-button-distance:0.5rem]',
        customCss
      )}
      ref={parentRef}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      data-testid="slider"
    >
      <div className="relative w-full">
        <NavButton
          aria-label="Navigate left"
          className={`left-[--bw-slider-nav-button-distance] ${NavBtnStyles}`}
          onClick={handleNavigateLeft}
          data-testid="slider-nav-left"
        >
          <span className="font-bwi text-primaryBlue w-[16px] h-[16px] leading-none -translate-x-[0.0625rem]">
            &#xe96b;
          </span>
        </NavButton>
        <NavButton
          aria-label="Navigate right"
          className={`right-[--bw-slider-nav-button-distance] ${NavBtnStyles}`}
          onClick={handleNavigateRight}
          data-testid="slider-nav-right"
        >
          <span className="font-bwi text-primaryBlue w-[16px] h-[16px] leading-none translate-x-0.5">&#xe91b;</span>
        </NavButton>
        <VirtualizedSlide
          x={x}
          index={index}
          totalCount={items.length}
          onDragEnd={handleEndDrag}
          containerRef={containerRef}
          forwardRenderCount={renderCount.forward}
          behindRenderCount={renderCount.behind}
        >
          {({ index: currentIndex }) => {
            const itemIndex = getCircularIndex(currentIndex, items.length)
            return <>{items[itemIndex]}</>
          }}
        </VirtualizedSlide>
        <div role="tablist" className="slider-dots flex absolute bottom-[24px] w-full items-center justify-center z-20">
          {items.map((item, itemIndex) => (
            <NavDot
              key={itemIndex}
              active={itemIndex === activeIndex}
              aria-label={`Go to slide ${itemIndex + 1} of ${items.length}`}
              colorVariant={dotColorVariant}
              className={cx(
                'w-2 h-2 rounded-full hover:opacity-80 transition-[opacity, transform] duration-200 mx-1 bg-primaryBlue',
                dotColorVariant === 'white' && `!bg-white`,
                itemIndex === activeIndex && `opacity-100 scale-125`,
                itemIndex !== activeIndex && `opacity-50 scale-100`,
                itemIndex !== activeIndex && `hover:opacity-100`
              )}
              onClick={() => {
                setIndex(itemIndex)
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
