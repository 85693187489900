import { useMemo } from 'react'
import { type DragHandlers, motion, MotionValue } from 'framer-motion'

import Slide from './slide'
import { getCircularIndex } from './utils'

type VirtualizedSlideTrackProps = {
  children: (props: { index: number }) => JSX.Element
  x: MotionValue<number>
  onDragEnd: DragHandlers['onDragEnd']
  containerRef: React.Ref<HTMLDivElement>
  /** The current slide index */
  index: number
  /** The total item count. Required because React.Children.count doesn't work since children is a function */
  totalCount?: number
  /** The amount of extra slides to render ahead of current slide. Min value is 1 and max is children length */
  forwardRenderCount?: number
  /** The amount of extra slides to render behind of current slide. Min value is 1 and max is children length */
  behindRenderCount?: number
}

export default function VirtualizedSlideTrack({
  children,
  x,
  index,
  onDragEnd,
  containerRef,
  totalCount,
  forwardRenderCount = 2,
  behindRenderCount = 2,
}: VirtualizedSlideTrackProps) {
  const { range, behindCount, forwardCount } = useMemo(() => {
    if (!totalCount) {
      return {
        range: [-1, 0, 1],
        behindCount: 1,
        forwardCount: 1,
      }
    }

    const behindLength = Math.min(Math.max(1, behindRenderCount), totalCount - 1)
    const forwardLength = Math.min(Math.max(1, forwardRenderCount), totalCount)
    return {
      range: [
        ...Array.from({ length: behindLength }, (_, i) => i - behindLength),
        0,
        ...Array.from({ length: forwardLength }, (_, i) => i + 1),
      ],
      behindCount: behindLength,
      forwardCount: forwardLength,
    }
  }, [children, behindRenderCount, forwardRenderCount])

  return (
    <motion.div
      ref={containerRef}
      className={`relative flex items-center`}
      style={{
        left: `${-(100 * behindCount) + index * 100}%`,
        right: `${-(100 * forwardCount) + index * 100}%`,
      }}
    >
      {range.map((rangeValue) => (
        <Slide
          key={rangeValue + index}
          x={x}
          onDragEnd={onDragEnd}
          active={rangeValue === 0}
          index={getCircularIndex(rangeValue + index, totalCount)}
          relativeIndex={rangeValue + index}
          renderSlide={children}
        />
      ))}
    </motion.div>
  )
}
