/* eslint-disable react/jsx-props-no-spreading */

// import React from 'react'
import { Experience } from '@ninetailed/experience.js-react'
import NinetailedProviderWrapper from '../ninetailed-provider'
import { mapNtExperiences } from '@/utils/ninetailed'
import PasswordGenerator from '@/components/section-password-generator'
import PasswordStrengthTester from '@/components/section-password-test'
import HostForm from '@/components/forms/host'
import JobsList from '@/components/ui/jobs-list'
import ComparisonGrid from '@/components/ui/comparison-grid'
import DownloadTabs from '@/components/ui/download-tabs'
import { useLiveUpdates } from '@/utils/live-preview'

type PrebuiltProps = {
  name: string
  properties: any
  entryId?: string
}

export function PrebuiltFunc({ name, properties }: PrebuiltProps): JSX.Element {
  return (
    <>
      {name === 'Password Generator' && <PasswordGenerator data={properties} />}
      {name === 'Password Strength Tester' && <PasswordStrengthTester />}
      {name === 'Host Form' && <HostForm data={properties} />}
      {name === 'Jobs list' && <JobsList jobs={properties} />}

      {(name === 'Business Comparison Table' ||
        name === 'Individual Comparison Table' ||
        name === 'Secrets Manager Plans' ||
        name === 'Business Comparison Table - Teams Variant' ||
        name === 'Business Comparison Table - Teams Baseline' ||
        name === 'Bitwarden Product Comparison') && <ComparisonGrid data={properties} />}
      {name === 'Download Extension' && <DownloadTabs {...properties} />}
    </>
  )
}

export default function Prebuilt({ entryId, ...fields }: any): JSX.Element {
  const {
    fields: { nt_experiences, id, ...props },
  } = useLiveUpdates({ fields, sys: { id: entryId } })
  return (
    <Experience
      {...props}
      id={id}
      key={id}
      experiences={mapNtExperiences(nt_experiences)}
      component={PrebuiltFunc}
      passthroughProps={{ ...props }}
    />
  )
}
